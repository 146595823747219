// continue from https://youtu.be/CUsCMKXpBGE?si=AFo5XKCdql1jTgxc&t=1334
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Leads from "./pages/Leads";
import Login from "./pages/Login";
import Search from "./pages/Search";
import Logout from "./pages/Logout";
import CallLogs from "./pages/CallLogs";
import AddLead from "./pages/AddLead";
import Dashboard from "./pages/Dashboard";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import MultiStepForm from "./components/Form.js/MultiStepForm";
import InfoCallLeads from "./pages/InfoCallLeads";
import AddInfoCallLead from "./pages/AddInfoCallLead";
import Interview from "./pages/Interview";
import TabComponent from "./pages/TabComponent";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/websiteleads" element={<Leads />} />
          <Route path="/search" element={<Search />} />
          <Route path="/login" element={<Login />} />
          <Route path="/addlead" element={<AddLead />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/calllogs/:id" element={<CallLogs />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/multistepform" element={<MultiStepForm />} />
          <Route path="/infocallleads" element={<InfoCallLeads />} />
          <Route path="/addinfocalllead" element={<AddInfoCallLead />} />
          <Route
            path="/editinfocalllead/:id"
            element={<AddInfoCallLead edit="true" />}
          />
          <Route path="/interview/:id/:name" element={<Interview />} />
          <Route path="/tab/:id/:name" element={<TabComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
