import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import SearchBar from "../components/SearchBar";
import axios from "axios";
import GenderDropdown from "../components/dropdowns/GenderDropdown";
import MaritalStatus from "../components/dropdowns/MaritalStatus";
import Ethnicity from "../components/dropdowns/Ethnicity";
import { Row, Spinner, Image } from "react-bootstrap-v5";
import Religion from "../components/dropdowns/Religion";
import Profession from "../components/dropdowns/Profession";
import { useNavigate } from "react-router-dom"; // to redirect to login page if not logged in
import { getTextForMaritalStatus } from "../model/UserProfile";
import AgeRangeSlider from "../components/dropdowns/AgeRangeSlider";
import { differenceInYears } from "date-fns";
import { getTextForReligion } from "../model/UserProfile";
import { getTextForProfession } from "../model/UserProfile";
import { getTextForEthnicity, getTextForStatus } from "../model/UserProfile";
import Status from "../components/dropdowns/StatusDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faCircleChevronRight,
  faExpandAlt,
} from "@fortawesome/free-solid-svg-icons";
import UpdateStatus from "../components/UpdateStatus";

export default function Search() {
  const [data, setData] = useState([]);

  const [gender, setGender] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [ethnicity, setEthnicity] = useState(null);
  const [minAge, setMinAge] = useState(null);
  const [maxAge, setMaxAge] = useState(null);
  const [religion, setReligion] = useState(null);
  const [profession, setProfession] = useState(null);
  const [status, setStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [lastQuery, setLastQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [moveUser, setMoveUser] = useState(null);

  const navigate = useNavigate();

  const genderCallback = (val) => {
    console.log(val);
    setGender(val);
  };

  const maritalCallback = (val) => {
    console.log(val);
    setMaritalStatus(val);
  };

  const ethnicityCallback = (val) => {
    console.log(val);
    setEthnicity(val);
  };

  const ageRangeCallback = (min_DOB, max_DOB) => {
    setMinAge(min_DOB);
    setMaxAge(max_DOB);
    console.log(min_DOB);
    console.log(max_DOB);
  };
  const religionCallBack = (val) => {
    console.log(val);
    setReligion(val);
  };
  const professionChange = (val) => {
    console.log(val);
    setProfession(val);
  };
  const statusCallBack = (val) => {
    console.log(val);
    setCurrentPage(1); // reset page when change filter by status
    setStatus(val);
  };

  const closeStatusPopup = () => {
    setShowPopup(!showPopup);
  };

  const onSearchFunction = (query) => {
    setLoading(true);
    var token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const queryParams = {
      searchTerm: query,
      gender: gender,
      maritalStatus: maritalStatus,
      ethnicity: ethnicity,
      minAge: minAge,
      maxAge: maxAge,
      religion: religion,
      profession: profession,
      status: status,
      page: currentPage,
    };

    console.log(queryParams);
    var url = `${process.env.REACT_APP_API_URL}/search`;
    console.log("api url", url);

    axios
      .get(url, {
        params: queryParams,
        headers: headers,
      })
      .then((res) => {
        console.log(res.data);

        setData(res.data.data);
        if (res.data.totalCountPages) {
          // only page 3 will have this
          setTotalPages(res.data.totalCountPages);
        }
        setLoading(false);
      })
      .catch((err) => {});
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    var token = localStorage.getItem("token");
    if (!token) {
      navigate("/login", { replace: true });
    }
    onSearchFunction(lastQuery);
  }, [
    gender,
    maritalStatus,
    ethnicity,
    currentPage,
    minAge,
    maxAge,
    religion,
    profession,
    status,
  ]);

  return (
    <>
      <NavBar />
      {showPopup && <UpdateStatus onClose={closeStatusPopup} user={moveUser} />}
      <h1 className="heading">Search for leads</h1>
      <SearchBar onSearch={onSearchFunction} />
      <div className="filterPanel">
        <Row style={{ boxShadow: "" }}>
          <div className="col-3">
            <GenderDropdown genderCallback={genderCallback} />
          </div>
          <div className="col-3">
            <MaritalStatus maritalCallback={maritalCallback} />
          </div>
          <div className="col-3">
            <Ethnicity ethnicityCallback={ethnicityCallback} />
          </div>
          <div className="col-3">
            <Religion religionCallBack={religionCallBack} />
          </div>
          <div className="col-3">
            <Profession professionChange={professionChange} />
          </div>
          <div className="col-3">
            <Status statusCallBack={statusCallBack} />
          </div>
          <div className="col-3">
            <AgeRangeSlider ageRangeCallback={ageRangeCallback} />
          </div>
        </Row>
      </div>
      <div>
        {loading ? (
          <Spinner animation="border" role="status" className="spinner">
            <span className="sr-only"></span>
          </Spinner>
        ) : (
          ""
        )}
        {data.length ? (
          <table className="table stripped">
            <thead>
              <tr>
                <th>name</th>
                <th>status</th>
                <th>email</th>
                <th>mobile_number</th>
                <th>gender</th>
                <th>marital-status</th>
                <th>ethnicity</th>
                <th>age</th>
                <th>religion</th>
                <th>profession</th>
              </tr>
            </thead>
            <tbody>
              {data.map((lead, index) => {
                const birthDate = new Date(lead.dob_date);
                const interviewLink = `/tab/${lead.id}/${lead.name_string}`;
                const age = differenceInYears(new Date(), birthDate);
                return (
                  <tr key={lead.id}>
                    <td>
                      <a
                        target="_blank"
                        href={`https://app.datehighflyers.com/admin/viewprofile/${lead.id}`}
                      >
                        {lead.name_string}
                      </a>
                      &nbsp;
                      <a href={interviewLink}>
                        <FontAwesomeIcon
                          icon={faExpandAlt}
                          style={{ color: "grey" }}
                        />
                      </a>
                    </td>
                    <td>
                      {getTextForStatus(lead.role_id)}&nbsp;
                      <FontAwesomeIcon
                        icon={faPencil}
                        onClick={() => {
                          setMoveUser(lead);
                          closeStatusPopup();
                        }}
                        className="pencil"
                        style={{
                          height: "15px",
                          float: "right",
                          color: "gray",
                        }}
                      />
                    </td>
                    <td>
                      <a
                        href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${lead.email}`}
                        target="_blank"
                      >
                        {lead.email}
                      </a>
                    </td>
                    <td>
                      <a
                        href={`https://wa.me/${lead.mobile_number}`}
                        target="_blank"
                      >
                        {lead.mobile_number}
                      </a>
                    </td>
                    <td>{lead.gender === 9501 ? "male" : "female"}</td>
                    <td>{getTextForMaritalStatus(lead.marital_status)}</td>
                    <td>{getTextForEthnicity(lead.ethnicity)}</td>
                    <td>{age}</td>
                    <td>{getTextForReligion(lead.describe_religion)}</td>
                    <td>{getTextForProfession(lead.profession)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <>
            <div className="noData">No Data</div>
            <Image src="images/DHF-LOGOblackr.png" className="fillerImage" />
          </>
        )}

        {data.length ? (
          <div>
            <button
              style={{ borderRadius: "5px" }}
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              First
            </button>
            <button
              style={{ borderRadius: "5px" }}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>{currentPage}</span>
            <button
              style={{ borderRadius: "5px" }}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
            <button
              style={{ borderRadius: "5px" }}
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              Last
            </button>
            <span>Total Pages: {totalPages}</span>
          </div>
        ) : null}
      </div>
    </>
  );
}
